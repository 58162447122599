import React from 'react'
import Swap from '../components/Swap'
import DCAOrder from '../components/DCAOrder'

const Invest = () => {
  return (
    <div className='flex justify-center items-center h-screen w-full bg-gray-100 dark:bg-black dark:text-white'>
        <div className='p-5 flex gap-x-5'>      
            <DCAOrder className="w-3/4"/>
            <Swap className="w-1/4"/>
        </div>    
    </div>
  )
};

export default Invest;
