// react
import React, {useState} from "react";
import {RiMoonClearFill, RiSunFill} from 'react-icons/ri'

// framer
import { motion } from "framer-motion"

// data
import { navbarData } from "../utils/data.js";

// link
import { Link } from 'react-router-dom';

import logo from "../assets/logo.png"

const Navbar = () => {

    // Collapsed menu hook:
    const [show, setshow] = useState(false);

    // Theme switch hook: 
    const [isOn, setIsOn] = useState(() => {

        if(typeof window !== "undefined") {
            if(localStorage.getItem('theme') === 'light'){
                return true;
            } else {
                return false;
            }
        }
    });

    // Theme switch:
    const toggleTheme = () => setIsOn(!isOn);

    // Transition animations: 
    const spring = {
        type: 'spring',
        stiffness: 700,
        damping: 30,
    };

    // Sets the theme:
    if(typeof window !== "undefined") {
        if (isOn) {
            document.documentElement.classList.remove('dark')
            localStorage.setItem('theme', 'light')
        } else {
            document.documentElement.classList.add('dark')
            localStorage.setItem('theme', 'dark')
        }
    
        if (
            localStorage.theme === 'light' || (!('theme' in localStorage) && window.matchMedia('(prefers-color-scheme: light)').matches)
        ) { document.documentElement.classList.add('dark') } 
        else {
            document.documentElement.classList.remove('dark')
        }
    }

    return (
        <div className="bg-gray-100 w-full dark:bg-black">
            <nav className="p-8">
                {/* For large and Medium-sized Screens */}
                <div className="flex justify-between">
                    <div className=" flex space-x-3 items-center">
                        <Link to="/">
                            <img width="40" height="20" src={logo} className="h-10 mr-3 border-green-300 border-2" alt="Superseed Logo"/>
                        </Link>
                        <h1 className="Logo-title font-bold text-2xl leading-6 text-green-300">{navbarData.title}</h1>
                        <Link to="/invest">
                            <p className="px-5 font-semibold text-sm leading-6 text-black dark:text-green-300">Invest</p>
                        </Link>
                    </div>
                    <div className="hidden sm:flex flex flex-row space-x-4 items-center">
            
                        {/* Btn: Blog */}
                        <Link to="https://ethglobal.medium.com/ethbogot%C3%A1-2022-8f9434b3a024">
                            <button className="flex p-2 font-semibold text-xs text-black focus:outline-none shadow-lg border-black border focus:bg-green-400 duration-150 justify-center items-center dark:text-white dark:focus:text-black dark:border-green-400">
                                {navbarData.blog}
                            </button>
                        </Link>

                        {/* Btn: About Us */}
                        <Link to="https://ethglobal.com/showcase/superseed-sch5g">
                            <button className="flex p-2 font-semibold text-xs text-black focus:outline-none shadow-lg border-black border focus:bg-green-400 duration-150 justify-center items-center dark:text-white dark:focus:text-black dark:border-green-400">
                                {navbarData.about_us}
                            </button>
                        </Link>

                        {/* Btn: Connect Wallet or Join Waitlist*/} 
                        <Link to="https://twitter.com/superseedclub">
                            <button className="flex p-2 font-semibold text-xs text-black focus:outline-none shadow-lg border-black border focus:bg-green-400 duration-150 justify-center items-center dark:text-white dark:focus:text-black dark:border-green-400">
                                Learn more
                            </button>
                        </Link>


                        {/* Theme switch */}
                        <div onClick={toggleTheme} className={`flex h-[30px] w-[60px] rounded-[50px] bg-zinc-300 p-[2.5px] shadow-inner hover:cursor-pointer dark:bg-zinc-700 ${ isOn && 'place-content-end'}`}>
                            <motion.div 
                                className="flex h-[25px] w-[25px] items-center justify-center rounded-full bg-black/90"
                                layout
                                transition={spring}
                            >
                                <motion.div whileTap={{rotate: 360}}>
                                {isOn ? (<RiMoonClearFill className="h-4 w-4 text-slate-200" />) : (<RiSunFill className="h-4 w-4 text-yellow-400" />)}
                            </motion.div>
                            </motion.div>    
                        </div>
                    </div>
                    <div className="flex flex-row justify-center items-center sm:hidden space-x-3">
                        {/* Theme switch */}
                        <div onClick= {toggleTheme}
                            className={`flex h-[30px] w-[60px] rounded-[50px] bg-zinc-100 p-[2.5px] shadow-inner hover:cursor-pointer dark:bg-zinc-700 ${ isOn && 'place-content-end'}`}>
                            <motion.div 
                                className="flex h-[25px] w-[25px] items-center justify-center rounded-full bg-black/90"
                                layout
                                transition={spring}
                            >
                                <motion.div whileTap={{rotate: 360}}>
                                {isOn ? (<RiMoonClearFill className="h-4 w-4 text-slate-200" />) : (<RiSunFill className="h-4 w-4 text-green-300" />)}
                            </motion.div>
                            </motion.div>    
                        </div>
                    
                        {/* Burger Icon */}
                        <div id="bgIcon" onClick={() => setshow(!show)} className={`flex flex-row focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800 justify-center items-center sm:hidden cursor-pointer`}>
                            <div className="bg-green-500 p-1 border border-white shadow-lg items-center">
                                <svg className={`${show ? 'hidden' : ''}`} width={24} height={24} viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                    <path className=" transform duration-150" d="M4 6H20" stroke="#1F2937" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M4 12H20" stroke="#1F2937" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    <path className=" transform duration-150" d="M4 18H20" stroke="#1F2937" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                                <svg className={`${show ? 'block' : 'hidden'}`} width={24} height={24} viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M18 6L6 18" stroke="#1F2937" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M6 6L18 18" stroke="#1F2937" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Mobile and small-screen devices (toggle Menu) */}
                <div id="MobileNavigation" className={`${show ? 'block' : 'hidden'} sm:hidden mt-4 mx-auto`}>
                    <div className="flex flex-col gap-4 mt-5 w-80 mx-auto">
                        <Link to="/services">
                            <button className="flex space-x-2 w-full h-10 font-semibold text-xs text-black bg-white border border-black shadow-lg focus:outline-none hover:bg-green-500 duration-150 justify-center items-center dark:bg-black dark:text-green-300 dark:border dark:border-green-300 dark:hover:bg-green-500 dark:hover:text-black">
                                {navbarData.services}
                            </button>
                        </Link>
                        <Link to="/services">
                            <button className="flex space-x-2 w-full h-10 font-semibold text-xs text-black bg-white border border-black shadow-lg focus:outline-none hover:bg-green-500 duration-150 justify-center items-center dark:bg-black dark:text-green-300 dark:border dark:border-green-300 dark:hover:bg-green-500 dark:hover:text-black">
                                {navbarData.blog}
                            </button>
                        </Link>
                        <Link to="/services">
                            <button className="flex space-x-2 w-full h-10 font-semibold text-xs text-black bg-white border border-black shadow-lg focus:outline-none hover:bg-green-500 duration-150 justify-center items-center dark:bg-black dark:text-green-300 dark:border dark:border-green-300 dark:hover:bg-green-500 dark:hover:text-black">
                                {navbarData.about_us}
                            </button>
                        </Link>

                        {/* Wallet Connection */}
                        {/* <button>
                        {navbarData.connect}
                        </button> */}
                    </div>
                </div>
            </nav>
        </div>
    );
}

export default Navbar
