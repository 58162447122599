// react
import React, { useState } from "react"

// data
import { heroData } from '../utils/data'
import hero from "../assets/hero_img.png"

// axios
import axios from "axios"

// toast
import { ToastContainer, toast } from "react-toastify"
import 'react-toastify/dist/ReactToastify.css';

const Landing = () => {

    const [email, setEmail] = useState("")
    const [state, setState] = useState("IDLE")
    const [errorMessage, setErrorMessage] = useState(null);

    const waitlist = async (e) => {

        setState("LOADING")
        setErrorMessage(null)

        // await axios.post(`https://superseed-api.vercel.app/waitlist?email=${email}`)
        // .then((response) => {
        //   console.log(response);
        // }, (error) => {
        //   console.log(error);
        // });

        try {
          console.log(email);
          const response = axios.get(`https://superseed-api.vercel.app/waitlist?email=${email}`);
          response.then((e) => console.log(e));
          setState("SUCCESS")

          toast('🦄 Thanks for signing up! Stay Tuned. 😉', {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
          
        } catch (e) {
          setErrorMessage(e.response.data.error)
          setState("ERROR")
          toast(' There was an error signing up, please try again! 💀', {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              });
        }
    }

     return (
        <div className="h-screen bg-gray-100 dark:bg-black">
            <div className="overflow-hidden dark:bg-black bg-gray-100">
                <div className="mt-0 container px-4 mx-auto dark:bg-black bg-gray-100">
                    {/* Hero Description & Waitlist */}
                    <div className="flex flex-wrap text-left dark:bg-black bg-gray-100">
                        <div className="w-full md:w-1/2 p-5">
                            <div className="inline-block mb-5 px-2 py-1 font-semibold bg-green-100 rounded-full shadow-md border-green-400 border">
                                <div className="flex flex-wrap items-center -m-1">
                                    <div className="w-auto p-1">
                                        <a className="text-xs text-wrap overflow-hidden" href="desc">💸 {heroData.desc_0}</a>
                                    </div>
                                </div>
                            </div>
                        
                        <h1 className="mb-5 text-4xl font-bold font-heading md:max-w-xl leading-20 dark:text-green-500 leading-12">{heroData.desc_1}</h1>
                        <p className="mb-5 text-xs text-gray-800 font-medium italic md:max-w-md dark:text-gray-50 leading-6">{heroData.desc_2}</p>
                       
                       <div className="mt-5">

                            <p className="mb-6 text-sm text-green-500 font-medium md:max-w-md dark:text-gray-50">{heroData.desc_5}</p>
                            
                            {/* Waitlist */}
                            <div className="flex grid grid-col gap-y-6">
                                <input
                                    className="block px-4 py-2 w-2/3 h-10 text-sm font-medium text-black border-2 border-black border shadow-lg dark:border-white dark:border dark:bg-gray-900 dark:text-white dark:placeholder:text-white"
                                    type="text"
                                    placeholder="Enter Email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                                <button 
                                    type="button"
                                    disabled={state === "LOADING"}
                                    onClick={waitlist}
                                    className="px-4 py-2 w-2/3 justify-center flex text-center h-10 text-black text-sm font-semibold bg-green-300 border-black border-2 shadow-lg md:items-center sm:items-center">
                                        {state === "LOADING" ? "Loading" : "Join Our Waitlist!"}
                                </button>
                                
                            </div>

                            {/* Status Update */}
                            <ToastContainer/>
                        </div>
                    </div>

                    {/* Hero Image */}
                    <div className="w-1/2 p-4 flex justify-center items-center  overflow-hidden xs:hidden xs:invisible s:hidden s:invisible ">
                        <img className="w-full h-auto justify-center items-center transform hover:-translate-y-10 transition ease-in-out duration-1000" src={hero} alt="Hero Img"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Landing;

