// router
import { Routes, Route } from "react-router-dom"

// pages
import Landing from "./pages/Landing"
import Invest from "./pages/Invest";

// components
import Navbar from './components/Navbar';

// ui
import './App.css';

function App() {
  return (
    <div className="App">
      <Navbar/>
      <Routes>
          {/* Landing */}
          <Route path="/" exact element={<Landing/>}/>

          {/* Investing */}
          <Route path="/invest" exact element={<Invest/>}/>

      </Routes>
    </div>
  );
}

export default App;
