// Navigation data:
export const navbarData = {
    title: "SUPERSEED",
    services: "Services",
    about_us: "About Us",
    blog: "Blog",
    connect: "Connect Wallet",
}

// Hero data: 
export const heroData = {
    desc_0: "Build Healthier Investing Habits",
    desc_1: "Unleash your investing potential with Superseed",
    desc_2: "Elevate your financial future: Where smart habits, impact investing, gamified learning & financial freedom meet.",
    desc_3: "Join Waitlist!",
    desc_4: "Learn More",
    desc_5: " 🥳 Join our waitlist today & get early access!",
}
