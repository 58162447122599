import React, {useEffect, useState} from 'react'

export default function SelectTokenModal({
  close,
  isOpen,
  choice,
  selectToken,
}) {

    const [tokens, setTokens] = useState();

    async function init(){
        console.log("Initializing");
        let response = await fetch('https://tokens.coingecko.com/uniswap/all.json');
        let tokenListJSON = await response.json();
        console.log("listing available tokens: ", tokenListJSON);

        setTokens(tokenListJSON.tokens);

        console.log(tokenListJSON.tokens);
    }

    useEffect(() => {
       
        init();
    }, [tokens, setTokens]);

  if (!isOpen) return

    return (
        <>
        <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            {!tokens ? (
                <div className="relative my-6 mx-auto w-2/5"> 
                    <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                        {/* <p>Test This</p> */}
                    </div>
                </div>    
            ) : (      
            <div className="relative my-6 mx-auto w-2/5">     
                {/*content*/}
                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                    {/*header*/}
                    <div className="flex items-start justify-between p-3 border-b border-solid border-slate-200 rounded-t">
                        <h3 className="text-sm font-semibold">Select token {choice}</h3>
                        <button
                            className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                            onClick={close}
                        >
                        <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                            ×
                        </span>
                        </button>
                    </div>

                    {/*body*/}
                    <div className="relative p-3 px-0 flex-auto overflow-y-scroll max-h-96">
                    {tokens.map((item, index) => (
                        <div
                            key={index}
                            onClick={() => selectToken(item.name, choice)}
                            className="w-full px-4 mb-2 py-2 cursor-pointer justify-between border-b-[0.5px] hover:bg-neutral-50 flex flex-col "
                        >
                            <div className='flex flex-row gap-x-5'>
                                <img alt="token logo" src={item.logoURI} className="w-6 h-6"></img>
                                <h1 className="text-sm ">{item.name}</h1>
                                <h1 className="text-sm text-green-500">{item.symbol}</h1>
                            </div>
                        </div>
                    ))}
                    </div>

                    {/*footer*/}
                    <div className="flex items-center justify-end p-3 border-t border-solid border-slate-200 rounded-b">
                        <button
                            className="text-red-500 bg-red-100 font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                            type="button"
                            onClick={close}
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
            )}        
        </div>
        </>
    )
}