import React, {useState } from 'react'
import SelectTokenModal from '../components/SelectTokenModal'

const Swap = () => {
    const [isOpen, setIsOpen] = useState(false)
    const [tokenFrom, setTokenFrom] = useState('From Token')
    const [tokenTo, setTokenTo] = useState('To Token')
    const [choice, setChoice] = useState('to')
  
    const open = (choiceType) => {
      setChoice(choiceType)
      setIsOpen(true)
    }
    const close = () => setIsOpen(false)
  
    const selectToken = (token, choice) => {
      if (choice === 'from') {
        setTokenFrom(token)
        setIsOpen(false)
      } else {
        setTokenTo(token)
        setIsOpen(false)
      }
    }
  
    // call create DCA order 
    async function createDCAOrder(){

        // use contract to call the createDCA method:
    }

    return (
        <>
        <div className='flex justify-center align-items-center h-screen bg-gray-100 dark:bg-black dark:text-white'>
            <div className="mt-5 p-4 h-min bg-white border border-black shadow-md shadow-green-400 dark:bg-gray-900 dark:border-green-500">
                {tokenFrom === tokenTo && (
                    <div className="flex text-red-400 font-medium flex-row self-center bg-red-50 px-4 py-2 text-sm rounded-md mb-3 border-2 border-red-400">
                        <h4>Swap tokens can not be the same</h4>
                    </div>
                )}

                <div className="p-2 w-full px-4 py-4 rounded-xl ">
                    <h1 className="font-semibold text-base">Choose Your Conversion</h1>

                    {/* Token From */}
                    <div className="w-full border-[0.5px] p-2 my-8">
                        <div className="flex px-2 py-3 flex-row items-center justify-between">
                            <h1 className="text-sm font-medium">{tokenFrom}</h1>

                            <button
                                onClick={() => open('from')}
                                className="text-black text-xs bg-green-300 px-2 py-1"
                            >
                            Change
                            </button>
                        </div>
                        <input
                            type="number"
                            placeholder="0.0"
                            className="bg-neutral-100 text-xl outline-none my-3 w-full p-2"
                        />
                    </div>

                    {/* Token To */}
                    <div className="w-full border-[0.5px] p-2 my-4">
                        <div className="flex px-2 py-3 flex-row items-center justify-between">
                            <h1 className="text-sm font-medium">{tokenTo}</h1>

                            <button
                            onClick={() => open('to')}
                            className="text-black text-xs bg-green-300 px-2 py-1"
                            >
                                Change
                            </button>
                        </div>

                        <input
                            type="number"
                            placeholder="0.0"
                            className="bg-neutral-100 text-xl outline-none my-3 w-full p-2"
                        />
                    </div>

                    {tokenFrom != tokenTo && (
                    <>
                        {/* <h4 className="text-neutral-700 text-sm">
                        Estimated gas fee:{' '}
                        </h4> */}
                        <button className="w-full p-3 my-3 bg-black rounded-md text-white">
                        Swap
                        </button>
                    </>
                    )}
                </div>
            </div>
        </div>

        <SelectTokenModal
           isOpen={isOpen}
           close={close}
           choice={choice}
           selectToken={selectToken}
        />
        </>
    )
}

export default Swap
