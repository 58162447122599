import { Fragment, React } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import classNames from 'classnames';

const DCAOrder = () => {
    return (
        <div className='flex p-5 bg-gray-100 dark:bg-black dark:text-white'>
            <div className="flex p-10 h-min bg-white border border-black shadow-md shadow-green-500 dark:bg-gray-900 dark:border-green-500  dark:shadow-green-500">
                <form className="space-y-6" action="#">
                    <h5 className="flex text-xl font-semibold text-center text-gray-900 dark:text-white">Lets convert your funds & DCA!</h5>
                    <div className="mt-8 flex grid gap-y-8">

                        {/* Conversion amount */}
                        <div className="flex flex-col">
                            <label className="mb-3 text-xs leading-none text-gray-800 dark:text-green-500">I want to convert:</label>
                            <input type="name" className="w-full bg-gray-50 text-xs shadow-md font-medium leading-none text-gray-800 p-3 border border-black dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white" placeholder="Minimum amount of $10 required." />
                        </div>

                        {/* Time Amount */}
                        <div className="flex flex-col">
                            <label className="mb-3 text-xs leading-none text-gray-800 dark:text-green-500">Over the course of:</label>
                            <input type="name" className="w-full bg-gray-50 text-xs shadow-md font-medium leading-none text-gray-800 p-3 border border-black dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white" placeholder="1 month (minimum)" />
                        </div>

                        {/* Frequency Amount */}
                        <div className="flex flex-col">
                            <label className="mb-3 text-xs leading-none text-gray-800 dark:text-green-500">Recurring DCA occurs:</label>
                            <input type="name" className="w-full bg-gray-50 text-xs shadow-md font-medium leading-none text-gray-800 p-3 border border-black dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white" placeholder="Daily, Weekly, Monthly" />
                        </div>

                        {/* Donation amount */}
                        <div className="flex flex-col">
                            <label className="mb-3 text-xs leading-none text-gray-800 dark:text-green-500">Amount to give:</label>
                            <input type="name" className="w-full bg-gray-50 text-xs shadow-md font-medium leading-none text-gray-800 p-3 border border-black dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white" placeholder="2%" />
                        </div>

                        {/* Donation note */}
                        <div className="flex flex-row justify-center text-center">
                            <label className="text-xs leading-none text-red-500">Note: The minimum amount to donate will be 2% of your profits earned</label>
                        </div>

                        {/* Donation dropdown */}
                        <Menu as="div" className="relative inline-block text-left">
                            <div>
                                <label className="inline-flex mb-3 text-xs leading-none text-gray-800 dark:text-green-500">I want my profits to support:</label>
                                <Menu.Button className="inline-flex w-full gap-x-2 bg-gray-50 px-3 py-2 text-xs font-semibold text-gray-900 shadow-md border border-black hover:bg-gray-50 dark:bg-gray-600 dark:border dark:border-gray-500 dark:placeholder-gray-400 dark:text-gray-400 ">
                                Select a funding option
                                    <ChevronDownIcon className="-mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
                                </Menu.Button>
                            </div>

                            <Transition
                                as={Fragment}
                                enter="transition ease-out duration-100"
                                enterFrom="transform opacity-0 scale-95"
                                enterTo="transform opacity-100 scale-100"
                                leave="transition ease-in duration-75"
                                leaveFrom="transform opacity-100 scale-100"
                                leaveTo="transform opacity-0 scale-95"
                            >
                                <Menu.Items className="absolute right-0 z-10 mt-2 w-full origin-top-right divide-y divide-gray-100 bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                <div className="py-1">
                                    <Menu.Item>
                                    {({ active }) => (
                                        <a
                                        href="#_"
                                        className={classNames(
                                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                            'block px-4 py-2 text-xs'
                                        )}
                                        >
                                        Eternal Optimist - [ Retro Public Goods ] 
                                        </a>
                                    )}
                                    </Menu.Item>
                                </div>
                                <div className="py-1">
                                    <Menu.Item>
                                    {({ active }) => (
                                        <a
                                        href="#_"
                                        className={classNames(
                                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                            'block px-4 py-2 text-xs'
                                        )}
                                        >
                                            Growing Open Source - [ Gitcoin Grants ] 
                                        </a>
                                    )}
                                    </Menu.Item>
                                </div>
                                <div className="py-1">
                                    <Menu.Item>
                                    {({ active }) => (
                                        <a
                                        href="#_"
                                        className={classNames(
                                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                            'block px-4 py-2 text-xs'
                                        )}
                                        >
                                        Community Choice - [ Community Voted ] 
                                        </a>
                                    )}
                                    </Menu.Item>
                                </div>
                                </Menu.Items>
                            </Transition>
                        </Menu>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default DCAOrder
